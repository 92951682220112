<template>
    <div>
        <v-container class="py-10">
            <h2 class="px-5 py-5">{{ $t('Quizzes Results L.M.S Report') }}</h2>
            <v-row>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
                        v-model="filters.from">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date"
                        v-model="filters.to">
                    </v-text-field>
                </v-col>
                <v-col md="3">
                    <AutoCompleteField end-point="/lms/quiz" :label="$t('Quiz Name')" v-model="filters.quiz_id" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField end-point="/user/auto-complete" :label="$t('Quiz Creator')"
                        v-model="filters.user_id" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('student name')" type="regRecord" v-model="filters.registration_record_id"
                        endPoint="/student/auto-complete" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('class')" v-model="filters.grade_id" endPoint="/grade" />
                </v-col>
                <v-col md="3">
                    <AutoCompleteField :label="$t('educitional class')" v-model="filters.edu_class_id"
                        endPoint="/edu-class" />
                </v-col>
                <v-col md="3"></v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="green" dark block @click="fetchData(1)">{{ $t('search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
                </v-col>
                <v-col md="3" class="mb-3 py-0">
                    <ExportExcel end-point="/lms/student-quiz/export" :params="filters" />
                </v-col>

                <v-col md="12">
                    <app-base-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
                        @re-fetch-paginated-data="fetchData($event)" :enableDelete="false">
                        <template slot="editFeature" slot-scope="{ row }">
                            <v-icon color="green" @click="$router.push(`/resultsDetailsStu/${row.id}`)">mdi-eye</v-icon>
                        </template>
                    </app-base-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ExportExcel from "../../../components/core/ExportExcel.vue";
import AutoCompleteField from "../../../components/core/AutoCompleteField.vue";

import axios from "axios";
export default {
    components: { ExportExcel, AutoCompleteField },
    data() {
        return {
            tableOptions: {
                tableData: [],
                tableHeaders: ["id", "date", "Quiz Name", "Quiz Creator", "student name", "educitional class",
                    "Quiz Results", "Percent", "Try Number", "settings"],
                // ,"Student Result" ,"class"
                accessTableData: [["id"], ["date"], ["quiz"], ["user"], ["student"], ["edu_class"], ["result"], ["percent"], ["attempt_number"]],
                loading: false,
                tableMeta: {
                    total: 10,
                    page: 1,
                },
            },
            filters: {
                from: null,
                to: null,
                quiz_id: null,
                user_id: null,
                edu_class_id: null,
                grade_id: null,
                registration_record_id: null,
                attempt_number: null,
            },
        };
    },
    computed: {
        ...mapGetters({
            units: "subjects/getUnits",
        }),
    },
    methods: {
        ...mapActions({
            fetchUnits: "subjects/fetchUnits",
        }),
        async fetchData(page) {
            this.tableOptions.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                size: 10,
                page: page,
                ...filterTemp,
            };
            const response = await axios.get(`/lms/student-quiz/report`, { params });
            if (response.status == 200) {
                console.log(response.data.data);
                this.tableOptions.tableData = response.data.data,
                    this.tableOptions.tableMeta.total = response.data.meta.total;
                this.tableOptions.tableMeta.page = response.data.meta.current_page;
            }
            this.tableOptions.loading = false;
        },
        clearFilter() {
            this.filters.user_id = undefined;
            this.filters.quiz_id = undefined;
            this.filters.from = undefined;
            this.filters.to = undefined;
            this.filters.attempt_number = undefined;
            this.filters.edu_class_id = undefined;
            this.filters.registration_record_id = undefined;
            this.filters.grade_id = undefined;
            this.fetchData(1);
        },
    },
    created() {
     this.fetchData(1);
    },
};
</script>
